<template>
  <div class="container">
    <!--    <h3 class="text-center mt-5 mb-5">qweqweqwjieopqwj</h3>-->
    <DataTable :value="graduateStudentList" tableStyle="min-width: 50rem" paginator :rows="10"
               :rowsPerPageOptions="[5, 10, 20, 50]" stripedRows responsiveLayout="scroll"
               :globalFilterFields="['fullname']" v-model:filters="filters" filterDisplay="row" :loading="loading">
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
        </div>
      </template>
      <Column field="fullname" header="Автор"></Column>
      <Column field="theme_name_ru" header="Наименование работы"></Column>
      <Column field="education_speciality_name" header="Образ овательная программа"></Column>
      <Column header="Действие">
        <template #body = "{data}">
          <a :href="data.is_passed?data.reference_url + '&access-token=' + getAccessToken:null"><Button :disabled="!data.is_passed">Справка</Button></a>
          <a :href="data.plagiat_url"><Button class="mt-1" :disabled="!data.plagiat_url">Плагиаризм</Button></a>
        </template>
      </Column>
    </DataTable>
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "DiplomaReference",
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'fullname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        }
      },
    }
  },
  methods: {
    ...mapActions('diplomaReference', ['GET_GRADUATE_STUDENT_LIST']),

    // confirmApproveDocOrder(id) {
    //   this.$confirm.require({
    //     message: 'Вы уверены что хотите утвердить приказ?',
    //     header: 'Утверждение приказа',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: async () => {
    //       await this.POST_APPROVE_ORDERS(id)
    //       const unixTime = this.date.getTime() / 1000 + this.date.getTimezoneOffset() * -60;
    //       await this.GET_DOC_ORDERS(unixTime)
    //     }
    //   });
    // },
  },
  watch: {},
  computed: {
    ...mapState('diplomaReference', ['graduateStudentList']),

    getAccessToken(){
      return getCookie('ACCESS_TOKEN')
    },

    authorField() {
      return this.graduateStudentList.map(student => `${student.first_name} ${student.last_name} ${student.middle_name}`).join(', ');
    }
  },
  async mounted() {
    await this.GET_GRADUATE_STUDENT_LIST()
    this.loading = false
  }
}
</script>

<style scoped>
</style>
